import React, { useState, useLayoutEffect, useContext } from 'react'
import { graphql } from 'gatsby';
import { css } from '@emotion/react';
import { ThemeContext } from '../components/theme-provider';
import SEO from '../components/seo';
import PortableText from '../components/portable-text';

export default function Contact({data}) {
  const theme = useContext(ThemeContext);

  useLayoutEffect(() => {
    theme.setLogoColorDark(true);
    theme.setMenuColorDark(true);
    theme.setBackgroundColor('#E1DDD2');
  }, []);

  return (
    <div
      className="type--large"
      css={css`
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        gap: var(--gutter);
        padding: 0 var(--margin);
        margin-top: 30rem;

        @media (max-width: 900px) {
          margin-top: 14rem;
        }
      `}
    >
      <SEO
        title="Contact"
      />
      <div
        css={css`
          grid-column: span 6;

          @media (max-width: 900px) {
            grid-column: span 12;
          }
        `}
      >
        <div
          css={css`
            margin-bottom: 7rem;

            @media (max-width: 900px) {
              margin-bottom: 4rem;
            }
          `}
        >
          <PortableText blocks={data.contact._rawIntroText} />
        </div>
      </div>
      <div
        css={css`
          grid-column-start: 8;
          grid-column-end: span 5;

          @media (max-width: 900px) {
            grid-column-start: 1;
            grid-column-end: span 12;
          }
        `}
      >
        <div
          css={css`
            margin-bottom: 22rem;

            @media (max-width: 1024px) {
              margin-bottom: 42rem;
            }

            @media (max-width: 900px) {
              margin-bottom: 6rem;
            }

            a {
              text-decoration: none;
              border-bottom: 0.3rem solid transparent;

              &:hover {
                border-bottom-color: var(--black);
              }
            }
          `}
        >
          <PortableText blocks={data.contact._rawContactText} />
        </div>
      </div>
    </div>
  );
}

export const query = graphql`
  query {
    contact: sanityContact(_id: {regex: "/(drafts\\\\.)?contact/"}) { 
      _rawIntroText
      _rawContactText
    }
  }
`
